.ant-input-number{
    width:100%;
    height: 38px;
}
.ant-form-item-control-input{
    width:100%;
    height: 38px;
}
.ant-input-number-input-wrap {
    height: 100%;
}
.ant-form-item-control-input-content{
    height: 100%;
}
.ant-input-number-input {
    height: 100%;
}
.ant-form-item-control-input-content input{
    height: 100%;
}
