.printContainer{
  display: 'flex';
  flex-direction: 'column';
  align-items: center;
  align-self: center;
  height: fit-content;  

}
.print {
  transform: rotate(270deg) translateX(-5.5cm);
  width: 21cm;
  height: 8cm;
  align-self: center;
  page-break-before: always;
  page-break-after: always;
  padding: 0 !important;
}
.print span {
  font-size: 10px;
  color: black;
  position: absolute;
  line-height: 12px;
}

.departure {
  bottom: 1.6cm;
  left: 7.5cm;
}

.date {
  bottom: 1.6cm;
  left: 4.2cm;
}

.seatNumber {
  bottom: 1.6cm;
  left: 9.75cm;
}

.tripNumber {
  top: 1cm;
  left: 4.25cm;
}

.tripSerial {
  top: 2cm;
  left: 7cm;
}

.price {
  bottom: 0.1cm;
  right: 3cm;
}
.priceTop {
  top: 3.25cm;
  left: 12.85cm;
}

.fromLocation {
  bottom: 2.9cm;
  left: 4.5cm;
}
.ticketCodeTop {
  bottom: 6.6cm;
  left: 6.5cm;
}
.toLocation {
  bottom: 2.9cm;
  left: 11.8cm;
}

.issueDateTop {
  top: 2.3cm;
  left: 12.85cm;
}
.issueDateBottom {
  bottom: 1cm;
  right: 1.9cm;
}
.tripId{
  bottom: 1.7cm;
  right: 2.5cm;

}
.qrCode {
  position: absolute;
  top: 1.5cm;
  right: 1.5cm;
}

